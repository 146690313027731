import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.compact.css';
import App from './App';
import StoreList from './component/StoreList';
import reportWebVitals from './reportWebVitals';

let componentName = 'App';
const nodes = document.querySelectorAll('[data-component]');
const components = {
  App: App,
  StoreList: StoreList
};

nodes.forEach((node) => {
  componentName = node.getAttribute('data-component');
  ReactDOM.render(
    React.createElement(components[componentName], null),
    node
  );
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
