import React, { Component } from 'react'
import { Skeleton, List, Card, Row, Col, Input, Select} from 'antd';

const { Search } = Input;
const { Option } = Select;
const apiPath = window.api_base_path + 'stores/' + window.api_key + '/getstores';
console.log(apiPath);
const listData = [];
for (let i = 0; i < 12; i++) {
  listData.push({
    title: `ant design part ${i}`
  });
}

class StoreList extends Component {
    constructor(props) {
        super(props);
        this.state = {
          error: null,
          isLoaded: false,
          items: [],
          filterParam: '',
          q:''
        };
    }
    onFliterSearch = (value) => {
        this.setState({
            filterParam: value
        });
    }
    onSearch = (value) => {
        this.setState({
            q: value
        });
    }
    onSearchChange = (e) => {
        this.setState({
            q: e.target.value
        });
    }
    search(items) {
        return items.filter(item => {
            if(this.state.filterParam > 0) {
                return item.categories.some(newItem => {
                    if(newItem.store_category_id === Number(this.state.filterParam)) {
                        return (
                            item.title
                                .toString()
                                .toLowerCase()
                                .indexOf(this.state.q.toLowerCase()) > -1
                        );
                    } else {
                        return '';
                    }
                });
            } else {
                return (
                    item.title
                        .toString()
                        .toLowerCase()
                        .indexOf(this.state.q.toLowerCase()) > -1
                );
            }
        });
    }
    componentDidMount() {
    fetch(apiPath)
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                isLoaded: true,
                items: result.items
                });
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                this.setState({
                isLoaded: true,
                error
                });
            }
        )
    }

    render() {
        const { error, isLoaded, items } = this.state;
        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return (
                <List
                grid={{
                    gutter: 16,
                    xs: 2,
                    sm: 3,
                    md: 4,
                    lg: 4,
                    xl: 6,
                    xxl: 6,
                }}
                dataSource={listData}
                renderItem={item => (
                <List.Item>
                    <Skeleton active>
                    </Skeleton>
                </List.Item>
                )}
            />
            )
        } else {
            return (
            <div>
            <Row>
                <Col span={12}>
                    <Search placeholder="search" allowClear onChange={this.onSearchChange} onSearch={this.onSearch} size="large"/>
                </Col>
                <Col span={12}>
                    <Select placeholder="Filter by category " allowClear size="large" onChange={this.onFliterSearch} style={{ width: '100%' }}>
                        {items.categories.map(item => (
                            !item.parent_id ? <Option key={item.id}>{item.title}</Option> : ''
                        ))}
                    </Select>
                </Col>
            </Row>
            <br/>
            <List
                grid={{
                    gutter: 16,
                    xs: 2,
                    sm: 3,
                    md: 3,
                    lg: 4,
                    xl: 6,
                    xxl: 6,
                }}
                dataSource={this.search(items.stores)}
                renderItem={item => (
                <List.Item>
                    <a href={item.link}>
                    <Card
                        title={item.title}
                        style={{ height:175, textAlign: "center" }}
                    >
                        <img
                            alt={item.title}
                            src={item.store_icon}
                            width={48}
                        />
                    </Card>
                    </a>
                </List.Item>
            )}
            />
            </div>
            )
        }
    }
}

export default StoreList;
